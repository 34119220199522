<template>
  <div class="">
    <van-nav-bar title="使用教程" left-arrow @click-left="$router.go(-1)" />
    <div class="content-padding">
      <van-pull-refresh v-model="loading" @refresh="refresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="已经加载完了"
          @load="loadData"
          immediate-check
        >
          <div
            class="news-item"
            v-for="news in data"
            :key="news.id"
            @click="$router.push('/services/course/' + news.id)"
          >
            <h2>{{ news.title }}</h2>
            <span class="date">发布时间：{{ news.postedAt }}</span>
            <van-image :src="news.image"></van-image>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  name: 'course',
  data () {
    return {
      data: [],
      page: 1,
      loading: false,
      finished: false
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    refresh () {
      this.page = 1
      this.data = []

      this.loadData()
    },
    loadData () {
      // this.loading = true

      // let query = { page: this.page }

      // this.$http.get('https://www.huami.com/v1/amazfit-courses', { params: query }).then((res) => {
      //   let data = res.data

      //   data.forEach((item) => {
      //     this.data.push(item)
      //   })

      //   if (data.length === 20) {
      //     this.finished = false
      //   } else {
      //     this.finished = true
      //   }

      //   this.loading = false

      //   this.page++
      // }).catch((e) => {
      //   this.$toast.fail('get courses error!')
      // })
      this.loading = true

      // let query = { page: this.page } // 目前就一页数据，先这样吧

      const data = [
        {
          id: 39,
          title: '全面的健康守护',
          image:
            'https://huami.cdn.bcebos.com/uploads/amazfit/course/5feafe0e9a3cc.png',
          description: '',
          postedAt: '2020-12-29 17:50:13'
        },
        {
          id: 38,
          title: '只要你伸手，啥事都能帮一手！',
          image:
            'https://huami.cdn.bcebos.com/uploads/amazfit/course/5fa13df68467a.png',
          description: '',
          postedAt: '2020-11-03 11:24:38'
        },
        {
          id: 36,
          title: '遨游音乐海洋，享受律动音乐生活',
          image:
            'https://huami.cdn.bcebos.com/uploads/amazfit/course/5f72f43d8de3e.png',
          description: '',
          postedAt: '2020-09-29 17:00:02'
        },
        {
          id: 35,
          title: '随叫随到，彻底解放双手',
          image:
            'https://huami.cdn.bcebos.com/uploads/amazfit/course/5f712c38d4e00.png',
          description: '',
          postedAt: '2020-09-28 08:05:52'
        },
        {
          id: 34,
          title: '血氧饱和度检测，贴身的健康管家',
          image:
            'https://huami.cdn.bcebos.com/uploads/amazfit/course/5f7061578146e.png',
          description: '',
          postedAt: '2020-09-27 17:50:31'
        },
        {
          id: 33,
          title: 'Amazfit ZenBuds使用教程',
          image:
            'https://huami.cdn.bcebos.com/uploads/amazfit/course/5f701d47018a4.png',
          description: '',
          postedAt: '2020-09-27 13:00:07'
        },
        {
          id: 32,
          title: 'Amazfit AirRun基础操作',
          image:
            'https://huami.cdn.bcebos.com/uploads/amazfit/course/5f700c07824d8.png',
          description: '',
          postedAt: '2020-09-27 03:50:31'
        },
        {
          id: 31,
          title: 'Amazfit PowerBuds运动模式与设置',
          image:
            'https://huami.cdn.bcebos.com/uploads/amazfit/course/5f70273644175.png',
          description: '',
          postedAt: '2020-09-27 03:17:07'
        },
        {
          id: 30,
          title: 'Amazfit Neo 按键说明',
          image:
            'https://huami.cdn.bcebos.com/uploads/amazfit/course/5f6dc015d9c11.png',
          description: '',
          postedAt: '2020-09-25 18:00:16'
        },
        {
          id: 26,
          title: 'Amazfit GTS 使用教程',
          image:
            'https://huami.cdn.bcebos.com/uploads/amazfit/course/5e0337bb0f4bd.jpg',
          description: '',
          postedAt: '2019-12-25 10:19:39'
        },
        {
          id: 25,
          title: 'Amazfit 智能运动手表 3 使用教程',
          image:
            'https://huami.cdn.bcebos.com/uploads/amazfit/course/5e032f0856c60.jpg',
          description: '',
          postedAt: '2019-12-25 09:42:32'
        },
        {
          id: 22,
          title: '“永恒时刻”表盘如何更换表针、刻度',
          image:
            'https://huami.cdn.bcebos.com/uploads/amazfit-news/5b289a7f3eed6.jpg',
          description: '',
          postedAt: '2018-06-14 08:35:02'
        },
        {
          id: 21,
          title: 'Amazfit 运动手表自定义背景和删除教程',
          image:
            'https://huami.cdn.bcebos.com/uploads/amazfit-news/5b222886b18fd.jpg',
          description: '',
          postedAt: '2018-06-14 08:34:14'
        },
        {
          id: 19,
          title: '如何开启 Amazfit 运动手表的全天心率监测',
          image:
            'https://huami.cdn.bcebos.com/uploads/amazfit-news/5b221edf97c20.jpg',
          description: '',
          postedAt: '2018-06-14 07:53:03'
        },
        {
          id: 16,
          title: '如何删除 Amazfit 运动手表上的运动记录',
          image:
            'https://huami.cdn.bcebos.com/uploads/amazfit-news/5b221e5179d2f.jpg',
          description: '',
          postedAt: '2018-06-14 07:50:41'
        },
        {
          id: 12,
          title: 'iOS 系统如何使用手表支付宝功能',
          image:
            'https://huami.cdn.bcebos.com/uploads/amazfit-news/5b221cf88b74d.jpg',
          description: '',
          postedAt: '2018-06-14 07:44:56'
        },
        {
          id: 10,
          title: '如何设置 Amazfit 运动手表的闹钟',
          image:
            'https://huami.cdn.bcebos.com/uploads/amazfit-news/5b221a5fd82c6.jpg',
          description: '',
          postedAt: '2018-06-14 07:33:51'
        },
        {
          id: 9,
          title: '蓝牙运动耳机选购指南',
          image:
            'https://huami.cdn.bcebos.com/uploads/amazfit-news/5b2219e581e9f.jpg',
          description: '',
          postedAt: '2018-06-14 07:31:49'
        },
        {
          id: 4,
          title: '教你如何设置Amazfit运动手表的运动心率提醒',
          image:
            'https://huami.cdn.bcebos.com/uploads/amazfit-news/5b2080884058b.jpg',
          description: '',
          postedAt: '2018-06-13 02:18:28'
        },
        {
          id: 7,
          title: 'Amazfit运动手表USB拷贝歌曲方法',
          image:
            'https://huami.cdn.bcebos.com/uploads/amazfit-news/5b22196e58b61.jpg',
          description: '',
          postedAt: '2018-06-12 01:25:41'
        }
      ]
      data.forEach(item => {
        this.data.push(item)
      })

      if (data.length === 20) {
        this.finished = false
      } else {
        this.finished = true
      }

      this.loading = false

      this.page++
    }
  }
}
</script>

<style lang="scss" scoped>
.news-item {
  h2 {
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 100%;
  }

  .date {
    display: inline-block;
    margin: 5px 0;
  }
}
</style>
